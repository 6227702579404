<script setup>
import DocumentUtils from "@/utils/DocumentUtils";
import {getCategory, getLabel, top5} from "@/api/article";
import {userSetting} from "@/api/sysSetting"
import {onMounted, ref} from "vue";
import DateUtils from "@/utils/DateUtils";
import {useRouter} from "vue-router";
//更改背景
DocumentUtils.setRootStyle("--var-index-bg-image","url('https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202005%2F19%2F20200519113551_AnmsP.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1679812137&t=75203672874e7088a50ad616c57d376f')")

const router = useRouter()

const data = ref({
  articleList:[],
  categoryList:[],
  labelList:[],
  searchArticleTitle:'',
  userSetting: [],
  defaultAvatar: 'https://img0.baidu.com/it/u=636622352,4230302227&fm=253&fmt=auto&app=138&f=JPEG?w=681&h=489'
})

const loadTop5 = () => {
  top5().then(res =>{
    if (res.success){
      data.value.articleList = res.data.items
    }
  }).catch(err =>{

  })
}

const loadCategory = () => {
  getCategory().then(res =>{
    if (res.success){
      data.value.categoryList = res.data
    }
  }).catch(err =>{

  })
}
const loadLabel = () => {
  getLabel().then(res =>{
    if (res.success){
      data.value.labelList = res.data
    }
  }).catch(err =>{

  })
}

const searchArticle = () => {
  console.log('搜索内容')
  router.push({
    name: 'List',
    state: {
      searchKey: data.value.searchArticleTitle
    }
  })
}

const toDetail = (param) => {
  router.push('article/detail/' + param)
}

const loadUserSetting = () =>{
  userSetting().then(res=>{
    // console.log('用户配置:', res.data)
    data.value.userSetting = res.data
  }).catch(err=>{

  })
}
onMounted(()=>{
  loadUserSetting()
  loadTop5()
  loadCategory()
  loadLabel()
})
</script>

<template>
  <div class="mx-home">
    <div class="mx-home-one">
      <div class="mx-user-msg">
        <div class="mx-user-msg-item" v-for="(item, index) in data.userSetting" :key="index">
          <div class="mx-user-avatar">
            <img :src="(item.avatar !=='' && item.avatar !== undefined) ? item.avatar : data.defaultAvatar" alt="头像">
          </div>
          <div class="mx-user-nickname">{{item.nickName}}</div>
        </div>
<!--        <div class="mx-user-msg-item">-->
<!--          <div class="mx-user-avatar">-->
<!--            <img src="https://img0.baidu.com/it/u=636622352,4230302227&fm=253&fmt=auto&app=138&f=JPEG?w=681&h=489" alt="头像">-->
<!--          </div>-->
<!--          <div class="mx-user-nickname">昵称昵称昵称昵称昵称昵称昵</div>-->
<!--        </div>-->
      </div>
      <div class="mx-day-word">
        你遇见我从来不是偶然，是我掐好时间站好地点的必然      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -- 网易云音乐《可能否》评论
      </div>
    </div>
    <div class="mx-home-tow">
      <div class="mx-hot-article">
        <div class="mx-hot-article-list">
          <div v-for="(item, index) in data.articleList" :key="index" class="mx-hot-article-card" @click="toDetail(item.id)">
            <div class="mx-hot-article-card-img">
              <img v-if="item.image !== null && item.image !== undefined && item.image !== ''" :src="item.image" alt="封面">
              <img v-else src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F201706%2F04%2F20170604150451_Wew5N.thumb.400_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1674206943&t=ecc4e0487c1dfeb57518fb1fd162dbf9" alt="封面">
            </div>
            <div class="mx-hot-article-card-content">
              <div class="mx-hot-article-content-title">{{ item.title }}</div>
              <div class="mx-hot-article-content-summary">{{item.subtitle}}</div>
              <div class="mx-hot-article-content-data">{{DateUtils.formatYear(item.updateTime)}}</div>
            </div>
          </div>
        </div>
        <div class="mx-hot-article-condition">
          <div class="mx-hot-condition-search">
            <div>
              <svg t="1670577706626" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="17077" width="200" height="200"><path d="M797.525333 752.266667c62.069333-72.736 97.28-165.002667 97.28-262.186667C894.816 266.528 713.621333 85.333333 490.08 85.333333 266.538667 85.333333 85.333333 266.538667 85.333333 490.069333 85.333333 713.610667 266.538667 894.826667 490.069333 894.826667a404.693333 404.693333 0 0 0 118.208-17.546667 32 32 0 0 0-18.666666-61.216 340.693333 340.693333 0 0 1-99.541334 14.762667C301.888 830.816 149.333333 678.261333 149.333333 490.069333 149.333333 301.888 301.888 149.333333 490.069333 149.333333 678.261333 149.333333 830.826667 301.888 830.826667 490.069333c0 89.28-35.381333 173.696-97.141334 237.322667a36.992 36.992 0 0 0 0.384 51.925333l149.973334 149.973334a32 32 0 0 0 45.258666-45.248L797.525333 752.266667z" p-id="17078" fill="#8a8a8a"></path></svg>
              <input v-model="data.searchArticleTitle" @keyup.enter="searchArticle" class="mx-article-search-input" />
              <span @click="searchArticle">搜索</span>
            </div>
          </div>
          <div class="mx-hot-article-category">
            <div class="mx-condition-top">
              分类
            </div>
            <div class="mx-condition-map">
              <span v-for="(item, index) in data.categoryList" :key="index">
                {{item.name}}
              </span>
            </div>
          </div>
          <div class="mx-hot-article-tag">
            <div class="mx-condition-top">
              标签
            </div>
            <div class="mx-condition-map">
              <span v-for="(item, index) in data.labelList" :key="index">
                {{item.name}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mx-home-one {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: aliceblue;
  background-image: var(--var-index-bg-image);
  background-repeat: no-repeat;
  background-size: cover;
}

.mx-user-msg {
  position: relative;
  min-width: 240px;
  height: 220px;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  /*border: 1px solid #1a1a1a;*/
}

.mx-user-msg-item{
  display: inline-block;
  min-width: 80px;
  text-align: center;
  margin: 0 35px;
  /*border: 1px solid #213547;*/
}
.mx-user-avatar{
  height: 80px;
  width: 80px;
  margin: 0 auto;
  border-radius: 40px;
  overflow: hidden;
}
.mx-user-avatar > img{
  width: 100%;
  height: 100%;
}
.mx-user-nickname{
  margin-top: 16px;
}

.mx-home-tow {
  width: 100%;
  min-height: 200px;
  margin-top: 20px;
}

.mx-day-word {
  position: absolute;
  width: 100%;
  bottom: 10vh;
}

.mx-hot-article {
  max-width: 840px;
  min-height: 540px;
  margin: 10px auto 0;
  border-radius: 8px;
  /*background-color: rgba(255,255,255,0.5);*/
  /*border: 1px solid #1a1a1a;*/
}

.mx-hot-article-list, .mx-hot-article-condition {
  display: inline-block;
  vertical-align: top;
  /*float: left;*/
  /*min-width: 400px;*/
  min-height: 350px;
  margin: auto 8px;
  /*border: 1px solid #1a1a1a;*/
}

.mx-hot-article-list {
  min-width: 420px;
  /*width: calc(100% - 436px);*/
}

.mx-hot-article-condition {
  width: 300px;
  /*margin-left: 26px;*/
}

.mx-hot-article-card {
  position: relative;
  width: 100%;
  height: 120px;
  overflow: hidden;
  margin-bottom: 12px;
  /*border-radius: 8px;*/
  background-color: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 5px 3px rgba(138, 138, 140, 0);
  transition: .5s;
  /*border: 1px solid #213547;*/
}

.mx-hot-article-card:hover {
  /*cursor: pointer;*/
  /*bottom: 3px;*/
  /*left: -2px;*/
  /*box-shadow: 4px 4px 15px #8a8a8c;*/
  box-shadow: 0 0 5px 3px #d8d8d8;
  transition: .5s;
}

.mx-hot-condition-search {
  width: 100%;
  height: 34px;
  line-height: 34px;
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  position: relative;
}

.mx-article-search-input{
  position: relative;
  top: 6px;
  right: 12px;
  width: 20px;
  opacity: 0;
  height: 18px;
  transition: .5s;
  border-radius: 10px;
  /*padding: 2px 3px;*/
  text-align: center;
  /*overflow: hidden;*/
  border: none;
  outline: 2px solid white;
  vertical-align: top;
}
.mx-hot-condition-search > div > svg{
  position: relative;
  top: 5px;
  left: 12px;
  height: 24px;
  width: 24px;
  z-index: 6;
}
.mx-hot-condition-search > div > span{
  font-size: 16px;
}
.mx-hot-condition-search > div > span:hover{
  color: #747bff;
  cursor: pointer;
}

.mx-article-search-input:focus{
  /*border: #747bff;*/
  border-radius: 10px;
  outline: 1px solid #2CD8D5;
}
.mx-hot-condition-search:hover .mx-article-search-input{
  padding: 2px 12px 2px 24px;
  width: 140px;
  transition: .5s;
  opacity: 1;
}

.mx-hot-article-category, .mx-hot-article-tag {
  width: 100%;
  min-height: 120px;
  margin: 8px auto;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.5);
}

.mx-condition-top {
  position: relative;
  padding: 5px 0;
}

.mx-condition-top:after {
  content: "";
  width: 86%;
  height: 2px;
  background-color: rgba(57, 161, 251, 0.3);
  margin: 0 auto;
  display: block;
}

.mx-condition-map {
  padding: 6px 6px;
}

.mx-condition-map > span {
  display: inline-block;
  padding: 3px 3px;
  margin: auto 3px;
  /*border: 1px solid #213547;*/
}

.mx-condition-map > span:hover {
  cursor: pointer;
  color: #c5c5ca;
}

.mx-hot-article-card-img, .mx-hot-article-card-content{
  display: inline-block;
  vertical-align: top;
  height: 100%;
}
.mx-hot-article-card-img{
  width: 120px;
  /*border-top-left-radius: 6px;*/
  overflow: hidden;
}
.mx-hot-article-card-img > img{
  width: 100%;
  height: 100%;
  transition: .35s;
}
.mx-hot-article-card:hover > .mx-hot-article-card-img > img{
  transition: .35s;
  transform: scale(120%);
}
.mx-hot-article-card-content{
  width: calc(100% - 136px);
  height: calc(100% - 16px);
  padding: 8px 8px;
}
.mx-hot-article-content-title, .mx-hot-article-content-summary, .mx-hot-article-content-data{
  /*border: 1px solid #213547;*/
}
.mx-hot-article-content-title{
  /*font-size: 18px;*/
  font-weight: bold;
  text-align: left;
}
.mx-hot-article-content-summary{
  margin: 3px auto 8px;
  text-align: left;
  font-size: 14px;
  height: 48px;
  width: 362px;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.mx-hot-article-content-data{
  /*margin-top: 8px;*/
  text-align: right;
  font-size: 14px;
  margin-right: 12px;
}
</style>
