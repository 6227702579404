import {provide, ref, inject, watchEffect} from "vue";

export function openModel(modelName){
    const name = ref(false)
    provide(modelName, name);

    function openAction(){
        console.log('=========')
        name.value = true
    }

    return { openAction }
}

export function closeModel(modelName){
    const visible = ref(false)
    const show = inject(modelName, ref(false))
    watchEffect(()=>{
        visible.value = show.value
    })

    function cancel(){
        visible.value = false
        show.value = false
    }

    function handleOk(func) {
        cancel()
        func && func()
    }

    return {visible, cancel, handleOk}
}