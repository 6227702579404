export default class DocumentUtils {
    static root = document.querySelector(":root")

    //设置自定义:root中的样式
    static setRootStyle(name, value) {
        DocumentUtils.root.style.setProperty(name, value);
    }
}


