import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from "./router/index.js";
//markdown
import VueMarkdownEditorComponent from './vue-markdown'
//跳转路由后自动滚动到顶部
router.afterEach((to,from,next) => {
    window.scrollTo(0,0);
});
const app = createApp(App);
app.use(router)
app.use(VueMarkdownEditorComponent)
app.mount('#app')
