import {createRouter, createWebHistory} from "vue-router";

const defaultRoutes = [
    {
        name: "Home",
        path: "/",
        component: () => import('../views/index/MxIndex.vue'),
        children: []
    },
    {
        name: "Article",
        path: "/article",
        component: () => import('../views/article/MxArticle.vue'),
        children: [
            {
                name: "List",
                path: "list",
                component: () => import('../views/article/MxArticleList.vue')
            },
            {
                name: "Detail",
                path: "detail/:id",
                component: () => import('../views/article/MxArticleDetail.vue')
            }
        ]
    },
    {
        name: "TimeLine",
        path: "/timeLine",
        component: () => import('../views/timeline/MxTimeLine.vue')
    },
    {
        name: "About",
        path: "/about",
        component: () => import('../views/about/MxAbout.vue')
    }
    , {
        name: "Login",
        path: "/login",
        component: () => import('../views/other/MxLogin.vue')
    }, {
        name: "404",
        path: "/404",
        component: () => import('../views/other/MxNotFound.vue')
    }, {
        path: '/:pathMatch(.*)*',
        redirect: '/404'
    }
]

export default createRouter({
    history: createWebHistory(),
    routes: defaultRoutes,
})
