/*
 * Http访问接口封装
 */

'use strict'

// import { ElMessage } from 'element-plus'
import axios from 'axios'
// import StorageUtil from "../utils/StorageUtil";
// import store from '../store/index'
import router from '../router/index'

/**
 * axios 设置默认值
 * @type {string}
 */
let apiUrl = 'https://api.imoxiao.cn' //apiURL
// let apiUrl = 'http://127.0.0.1:9668/web/v1' //测试

axios.defaults.baseURL = apiUrl //测试

axios.defaults.timeout = 30000; // 30秒超时
axios.defaults.withCredentials = false;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.responseType = 'json';
let jsonContentType = 'application/json;charset=UTF-8';
let fileContentType = 'multipart/form-data'
/*
 * axios 创建实例时设置配置的默认值
 */
let instance = axios.create({
    baseURL: ''
});

// 在实例已创建后修改默认值
instance.defaults.headers.common['Authorization'] = "";

/*
 * 请求拦截
 * 通过request 拦截器，可以需要的参数传到服务端
 * 示例为获取Cookie中存储的session传输到后台
 */
axios.interceptors.request.use(
    config => {
        // Tip: 1
        // 请求开始的时候可以结合 vuex 开启全屏的 loading 动画
        // Tip: 2
        // 带上 token , 可以结合 vuex 或者重 localStorage
        // 让每个请求携带token--['X-Token']为自定义key 请根据实际情况自行修改
        // if (localStorage.getItem('token')) {
        //     config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
        // }

        // 设置请求头token
        // let sessionId = StorageUtil.getLocal('sessionId')

        // if (sessionId) {
        //    config.headers['token'] = sessionId;
        // }

        // Tip: 3
        //根据请求方法，序列化传来的参数，根据后端需求是否序列化
        if (config.method.toLocaleLowerCase() === 'post' ||
            config.method.toLocaleLowerCase() === 'put' ||
            config.method.toLocaleLowerCase() === 'delete') {

            /*let uri = config.url
            if (uri.includes('resource',0) && uri.includes('upload',0)){
                config.baseURL = resourceUrl
                config.headers['Content-Type'] = fileContentType
                config.headers['Accept'] = '*!/!*'
                console.log("上传资源",config)
            }else {
                config.baseURL = adminUrl
                config.headers['Content-Type'] = jsonContentType
                config.headers['Accept'] = 'application/json'
                config.data = JSON.stringify(config.data)
                // console.log('数据请求:',config)
            }*/

            config.headers['Content-Type'] = jsonContentType
            config.headers['Accept'] = 'application/json'
            config.data = JSON.stringify(config.data)
        }

        return config
    },
    err => {
        console.log("请求超时");
        return Promise.reject(err)
    }
)

/*
 * 响应拦截
 */
instance.interceptors.response.use(
    response => {
        /*
        if (response) {
            // 响应处理
            // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
            // 否则的话抛出错误
            if (response.status === 200) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(response)
            }
        }
        */
        return response
    },
    err => {
        // 根据和后台开发人员协商好的错误状态码，进行相应的错误处理
        // 几个常见的操作处理
        if (err.response.status === 504 || err.response.status === 404) {
            console.log("服务器被吃了⊙﹏⊙∥");
        } else if (err.response.status === 401) {
            console.log("登录信息失效⊙﹏⊙∥");
        } else if (err.response.status === 500) {
            console.log("服务器开小差了⊙﹏⊙∥");
        }
        return Promise.reject(err)
    }
)

/**
 * get请求
 * @param {String} url 请求地址
 * @param {Object} params 请求参数
 * @returns {Promise}
 */
export function get(url, params) {
    url = url + '?v=' + Math.random();

    return new Promise((resolve, reject) => {
        axios.get(url, { params: params }).then((res) => {
            // console.log('res==',res)
            //全局请求拦截
            if (res.data.status === 2){
                // store.commit('setAuth',false)
                router.push('/login')
            }
            resolve(res.data)
        }).catch((error) => {
            reject(error.data)
        })
    })
}

export function get2(url, params) {
    url = url + '?v=' + Math.random()

    return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                // headers: {
                //     'token': parseInt(sessionId)
                // }
            }).then((res) => {
                // resolve(res.data)
                if (res.data.status == 1) {
                    // ElMessage.error('请先登录~');
                    window.location.href = '/login'
                } else {
                    resolve(res.data)
                }
            }).catch((error) => {
                reject(error)
            })
    })

}
/*
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 * @param {Object} data [请求时携带的body数据]
 */
export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then((res) => {
            //全局请求拦截
            if (res.data.status === 2){
                // store.commit('setAuth',false)
                router.push('/login')
            }
            resolve(res.data)
        }).catch((error) => {
            reject(error.data)
        })
    })
}


export function post2(url, data) {
    url = url + '?v=' + Math.random()

    return new Promise((resolve, reject) => {
        axios.post(
            url,
            data,
            // {
            //     headers: header
            // }

        ).then((res) => {
            // resolve(res.data)
            if (res.data.status == 2) {
                // ElMessage.error('请先登录~');
                window.location.href = '/login'
            } else {
                resolve(res.data)
            }
        }).catch((error) => {
            reject(error.data)
        })
    })


}

export function postZip(url, data) {
    url = url + '?v=' + Math.random()

    return new Promise((resolve, reject) => {
        axios.post(
            url,
            data,
            {
                responseType: 'blob',
                headers:{ 'Content-Type': 'application/json; application/octet-stream'}
            },

        ).then((res) => {
            resolve(res)
        }).catch((error) => {
            reject(error)
        })
    })


}
