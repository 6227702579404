<script setup>
import {useRouter} from 'vue-router'
import {openModel} from "@/common/js/TopWindown";
import MxLogin from "../../views/other/MxLogin.vue";

const router = useRouter()
const menuList = [
  {
    title:'首页',
    path: '/'
  },
  {
    title:'学习&笔记',
    path: '/article/list'
  },
  {
    title:'时间线',
    path: '/timeLine'
  },
  {
    title:'关于',
    path: '/about'
  }
]
const goTo = (path)=>{
  // console.log('currentRouter', router.currentRoute.value.path)
  // console.log('跳转',path)
  router.push(path)
}
const {openAction} = openModel('notice')
const openDialog = () =>{
  openAction()
}

</script>

<template>
  <div id="mx-menu" v-if="router.currentRoute.value.path !== '/404'">
    <div class="mx-menu-inline-left">
      <div class="mx-name" @click="goTo('/')">沫晓の云上之家</div>
      <span v-for="(item, index) in menuList" :key="index"
            :class=" router.currentRoute.value.path === item.path ? 'current-page' : ''"
            @click="goTo(item.path)">
        {{item.title}}
      </span>
    </div>
    <div class="mx-menu-inline-right">
      <span class="mx-login-btn" @click="openDialog">登录</span>
    </div>
  </div>
  <MxLogin></MxLogin>
</template>

<style scoped>
#mx-menu{
  position: absolute;
  width: calc(100% - 20px);
  min-width: 420px;
  height: 42px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  /*border: 1px solid #1a1a1a;*/
  background-color: rgba(255,255,255,0.5);
  line-height: 42px;
  z-index: 3;
}
.mx-menu-inline-left, .mx-menu-inline-right{
  position: absolute;
  display: inline-block;
}
.mx-menu-inline-left{
  left: 12px;
}

.mx-menu-inline-right{
  right: 0;
}
.mx-name, .mx-menu-inline-left > span, .mx-menu-inline-right > span{
  position: relative;
  display: inline-block;
  height: 100%;
  padding: 0 8px;
}
.mx-name{
  padding: 0 8px 0 0;
}

.mx-name:hover{
  cursor: pointer;
}
.mx-login-btn{
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.mx-menu-inline-left > span:hover, .mx-login-btn:hover{
  cursor: pointer;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
}
.current-page:after{
  position: absolute;
  content:"";
  /*width:1rem;*/
  height:3px;
  width: calc(100% - 16px);
  background-color: rgba(57, 161, 251, 0.3);
  margin:0 auto;
  bottom: 8px;
  display:block;
}
</style>
