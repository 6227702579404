import {get, post} from "./http";

const qqLogin = (params) => {
    return new Promise((resolve, reject) => {
        get('/qqlogin/qq', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export {
    qqLogin
}