<script setup>
import qqLogo from "@/assets/qq_symbol.png"
import {closeModel} from "@/common/js/TopWindown";
import {qqLogin} from "@/api/auth";

function qQLogin() {
  console.log('qq登录')
  qqLogin().then(res=>{
    console.log('发送',res)
  })
}
const {visible, cancel, handleOk} = closeModel('notice')
</script>

<template>
  <div class="top-masks"
       v-show="visible"
       @click="cancel"
       @ok="handleOk">
    <div class="login-top" @click.stop>
      <svg @click="cancel" t="1676276186514" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2328" width="32" height="32">
        <path d="M807.127074 186.990435c-7.695491 0-15.390982 2.898302-21.187585 8.794847L195.085692 786.539137c-11.693149 11.693149-11.693149 30.682022 0 42.375171 5.896545 5.896545 13.492094 8.794847 21.187585 8.794847 7.695491 0 15.390982-2.898302 21.187586-8.794847l590.753855-590.753855c11.693149-11.693149 11.693149-30.682022 0-42.375171-5.796604-5.896545-13.492094-8.794847-21.087644-8.794847z" fill="#bfbfbf" p-id="2329"></path>
        <path d="M216.872926 186.490728c-7.695491 0-15.390982 2.898302-21.187585 8.794847-11.693149 11.693149-11.693149 30.682022 0 42.375171l590.653913 590.653913c5.896545 5.896545 13.492094 8.794847 21.187586 8.794847s15.390982-2.898302 21.187585-8.794847c11.693149-11.693149 11.693149-30.682022 0-42.37517l-590.653914-590.653914c-5.796604-5.896545-13.492094-8.794847-21.187585-8.794847z" fill="#bfbfbf" p-id="2330"></path></svg>
      <div class="top-content">
        <div>
          <span>登录</span>
        </div>
        <div style="margin-top: 40px">
          <div>
            <span>账号:</span>
            <span><input class="account-input" placeholder="account" /></span>
          </div>
          <div>
            <span>密码:</span>
            <span><input class="account-input" placeholder="password" /></span>
          </div>
        </div>
        <div>
          <div class="login-btn">登录</div>
        </div>
        <div class="other-login">
          <span>— </span>
          <span class="logo-img"><img :src="qqLogo" @click="qQLogin"></span>
          <span> —</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.top-masks{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 30;
}
.login-top{
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 300px;
  background-color: #fdfcfb;
  border-radius: 6px;
  z-index: 99;
}
.login-top svg{
  position: absolute;
  width: 20px;
  height: 20px;
  right: 6px;
  top: 6px;
  /*transform-origin: top right;*/
  transform-box: fill-box;
  transform: rotate(0deg);
  transition: 0.35s;
}
.login-top svg:hover{
  cursor: pointer;
  transform: rotate(90deg);
  /*transition: 0.5s;*/
}
.login-top svg:hover path{
  fill: #97d9e1;
}
.top-content{
  /*border: 1px solid #7b7b7d;*/
  margin-top: 26px;
  margin-left: 10px;
  height: calc(100% - 40px);
  width: calc(100% - 20px);
  color: #3c3c3c;
}
.account-input{
  background: none;
  outline: none;
  border: 0;
}
.account-input{
  margin-left: 6px;
  margin-bottom: 8px;
  height: 22px;
  font-size: 14px;
  border: 1px solid #97d9e1;
  border-radius: 3px;
  text-align: center;
}
.account-input::placeholder{
  color: rgba(123, 123, 125, 0.5);
}
.account-input:focus{
  border: 1px solid #1b8b99;
}
.login-btn{
  background-color: #97d9e1;
  margin-top: 20px;
  width: 176px;
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  margin-left: calc(50% - 68px);
}
.login-btn:hover{
  cursor: pointer;
  background-color: #71d2e2;
}
.other-login{
  margin-top: 20px;
  color: #DCD9D4;
}
.logo-img img{
  display: inline-block;
  width: 18px;
  /*height: 30px;*/
}
.logo-img img:hover{
  cursor: pointer;
}
</style>
