import {get, post} from "./http";

const userSetting = (params) => {
    return new Promise((resolve, reject) => {
        get('/setting/user', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export {
    userSetting
}