<script setup>

import {ref} from "vue";

const operationHours = ref({
  day: 0 ,
  hours: 0,
  minute: 0,
  second: 0
})
let startTime = new Date('2019-01-01 00:00:00')
// eslint-disable-next-line no-unused-vars
let x = setInterval(function () {
  let nows = new Date();
  let timeDifference = parseInt(nows - startTime) / 1000;
  // y.innerHTML=nows.getFullYear();//获取当前年份
  // m.innerHTML=nows.getMonth()+1;//月份从0开始
  // d.innerHTML=nows.getDate();//获取当前月份中的天（getDay()方法是获取周几：一周的第几天）
  operationHours.value.day = Math.floor(timeDifference / 60 / 60 / 24);
  operationHours.value.hours = getTrueNumber(Math.floor(timeDifference / 60 / 60 % 24));
  operationHours.value.minute =  getTrueNumber(Math.floor(timeDifference / 60 % 60 ));
  operationHours.value.second =  getTrueNumber(Math.floor(timeDifference % 60));

}, 1000);

function queryBeiAn() {
  // location.href = "https://beian.miit.gov.cn/";
  window.open('https://beian.miit.gov.cn/', '_blank')
}

function getTrueNumber(x) {
  return (x < 10) ? ('0' + x) : x;
}
</script>

<template>
  <div id="mx-floor">
    <div class="mx-floor-about">
      <span>沫晓の云上之家</span>
      <em> | </em>
      <span @click="queryBeiAn">豫ICP备20004181号</span>
      <em> | </em>
      <span>关于</span>
      <div class="mx-operation_hours">
        <span>站点已运行：{{operationHours.day}}天{{ operationHours.hours }}小时{{ operationHours.minute }}分{{ operationHours.second }}秒</span>
      </div>
    </div>
  </div>
</template>

<style scoped>
#mx-floor {
  /*position: absolute;*/
  /*bottom: 0;*/
  margin-top: 20px;
  height: 80px;
  width: 100%;
  /*background-color: #213547;*/
  background-color: #22202e;
  /*border: 1px solid #1a1a1a;*/
}

.mx-floor-about {
  position: relative;
  /*border: 1px solid #97d9e1;*/
  height: 30px;
  width: 420px;
  margin: auto;
  top: 18px;
}

.mx-floor-about > span {
  height: 100%;
  line-height: 30px;
  color: #a3a3a7;
  font-size: 14px;
}
.mx-operation_hours{
  /*line-height: 30px;*/
  color: #a3a3a7;
  font-size: 14px;
}
.mx-floor-about > em {
  color: #a3a3a7;
}

.mx-floor-about > span:hover,.mx-operation_hours:hover {
  cursor: pointer;
  color: #c5c5ca;
}
</style>
