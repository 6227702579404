import {get, post} from "./http";

const top5 = (params) => {
    return new Promise((resolve, reject) => {
        get('/article/top5', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const getPage = (params) => {
    return new Promise((resolve, reject) => {
        get('/article/page', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const getDetail = (params) => {
    return new Promise((resolve, reject) => {
        get('/article/detail', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

const getCategory = (params) => {
    return new Promise((resolve, reject) => {
        get('/article/category', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}
const getLabel = (params) => {
    return new Promise((resolve, reject) => {
        get('/article/label', params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    })
}

export {
    top5,
    getPage,
    getDetail,
    getCategory,
    getLabel
}